import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faTimesCircle,
    faCloudUploadAlt,
} from '@fortawesome/free-solid-svg-icons'

interface FileUploaderComponentProps {
    setField: any
    fieldValue: any
    fieldName: string
    text: string
}

const FileWrapper = styled('div')`
    ${({ theme }) => `
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-height: 16rem;
        background: #f5f5f5;
        border-radius: ${theme?.spacing?.spc10};
        padding: ${theme?.spacing?.spc10} ${theme?.spacing?.spc10};
    `}
`

const ImagePreview = styled('img')`
    ${({ theme }) => `
        width:100%;
        height: 100%;
        max-height: 14rem;
        max-width: 15rem;
    `}
`

const FileUploadInput = styled('label')`
    ${({ theme }) => `
       display: flex;
       align-items: center;
       justify-content: center;
       flex-direction: column;
       height: 100%;
       width: 100%;
       cursor: pointer;
       svg{
            font-size: ${theme?.fontSizes?.font35};
       }
    `}
`
const RemoveIcon = styled('span')`
    ${({ theme }) => `
        color: ${theme?.colors?.red};
        margin-top: ${theme?.spacing?.spc10};
        cursor: pointer;
        svg{
            font-size: ${theme?.fontSizes?.font22};
        }
    `}
`

function FileUploaderComponent({
    setField,
    fieldValue,
    fieldName,
    text = 'Agregar un ficheiro',
}: FileUploaderComponentProps) {
    // States
    const [preview, setPreview] = useState('')

    const handleChange = (evt: any) => {
        if (evt?.currentTarget?.files?.length > 0) {
            const previewlink = URL.createObjectURL(evt.currentTarget.files[0])
            setPreview(previewlink)
            setField(fieldName, evt.currentTarget.files[0])
        } else {
            setField(fieldName, null)
        }
    }

    const handleReset = () => {
        setField(fieldName, null)
        setPreview('')
    }

    useEffect(() => {
        if (fieldValue?.url) {
            setPreview(fieldValue?.url)
        }
    }, [fieldValue])

    return (
        <FileWrapper>
            {preview ? <ImagePreview src={preview} alt={fieldName} /> : null}
            {!preview ? (
                <FileUploadInput htmlFor={`uploadField${fieldName}`}>
                    {text}
                    <FontAwesomeIcon icon={faCloudUploadAlt} />
                </FileUploadInput>
            ) : null}

            <input
                id={`uploadField${fieldName}`}
                style={{ display: 'none' }}
                type="file"
                onChange={(e) => handleChange(e)}
                name={`uploadField${fieldName}`}
            />

            {preview ? (
                <RemoveIcon onClick={() => handleReset()}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                </RemoveIcon>
            ) : null}
        </FileWrapper>
    )
}

export default FileUploaderComponent
