// Modules
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

// Components
import { Button } from '@mui/material'
import Dashboard from '../Dashboard'
import { BreadcrumbItem, Panel, TableComp } from '../../components'

// Icons
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import PersonAddIcon from '@mui/icons-material/PersonAdd'

// Containers
import DeleteBox from './DeleteUser'

// Redux actions
import { toastErro } from '../../redux/toast/actions'
import { useDispatch } from 'react-redux'

// Services
import { getAllUsers } from '../../services/auth'

const ActionItem = styled('span')`
    ${({ theme }) => `
        display: flex;
        align-items:center;
        justify-content: center;
        cursor: pointer;
        margin-right:${theme?.spacing?.spc4};
        svg{
            color: ${theme.colors.darkBlue};
            font-size:${theme?.fontSizes?.font24};
        }
    `}
`

const Users: React.FC<{}> = () => {
    // States
    const [isOpen, setIsOpen] = useState(false)
    const [deleteId, setDeleteId] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [refreshKey, setRefreshKey] = useState(0)

    // Table states
    const [tableData, setTableData] = useState([])
    const [pageSize, setPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [total, setTotal] = useState(0)

    // Hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // Methods
    const addUser = () => {
        navigate(`/dashboard/users/addUser`)
    }

    // Columns
    const columns = React.useMemo(() => {
        const editUser = (userId: any) => {
            navigate(`/dashboard/users/${userId}`)
        }

        const deleteUser = (userId: string | undefined) => {
            setDeleteId(userId || '0')
            setIsOpen(true)
        }

        return [
            {
                Header: 'Clínica',
                accessor: 'name',
                width: 250,
            },
            {
                Header: 'Correo',
                accessor: 'email',
                width: 200,
            },
            {
                Header: 'Teléfono',
                accessor: 'phone',
                width: 200,
            },
            {
                Header: 'Función',
                accessor: 'role',
                width: 50,
            },
            {
                Header: 'Activo',
                accessor: 'isActive',
                width: 50,
            },
            {
                Header: 'Editar',
                accessor: 'actions',
                width: 50,
                noSort: true,
                Cell: (props: any) => {
                    const userId = props.row.original._id
                    return (
                        <ActionItem>
                            <ModeEditIcon onClick={() => editUser(userId)} />
                            <DeleteForeverIcon
                                onClick={() => deleteUser(userId)}
                            />
                        </ActionItem>
                    )
                },
            },
        ]
    }, [navigate])

    // Use Effects
    useEffect(() => {
        getAllUsers()
            .then((resp: any) => {
                const arrUsers: any = []
                resp?.data?.data?.data?.forEach((user: any) => {
                    arrUsers.push({
                        _id: user?._id,
                        name: `${user?.firstName} ${user?.lastName}`,
                        email: user?.email,
                        phone: user?.phone,
                        role: user?.role,
                        isActive: user?.isActive ? 'Sí' : 'No',
                    })
                })
                setTableData(arrUsers)
                setTotal(resp?.data?.data?.total)
                setIsLoading(false)
            })
            .catch((err: any) => {
                dispatch(toastErro('Erro!'))
                setIsLoading(false)
            })
    }, [dispatch, refreshKey])

    const breadcrumbs = [<BreadcrumbItem text={'Usuarios'} />]

    return (
        <Dashboard>
            <>
                <Panel
                    title={'Gestión de usuarios'}
                    buttons={[
                        <Button
                            onClick={() => addUser()}
                            variant="outlined"
                            style={{
                                background: '#0b1843',
                                color: '#fff',
                            }}
                        >
                            <PersonAddIcon />
                            Crear usuario
                        </Button>,
                    ]}
                    breadcrumbs={breadcrumbs}
                    isLoading={isLoading}
                >
                    <TableComp
                        columns={columns}
                        data={tableData}
                        canPreviousPage={currentPage !== 1}
                        canNextPage={
                            currentPage !== Math.ceil(total / pageSize)
                        }
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={Math.ceil(total / pageSize)}
                    />
                </Panel>
                <DeleteBox
                    id={deleteId}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    setRefreshKey={setRefreshKey}
                />
            </>
        </Dashboard>
    )
}

export default Users
