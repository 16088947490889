import jwt_decode from 'jwt-decode'

export interface AuthStateIT {
    _id: number | null
    email: string
    username: string
    name: string
    lastName: string
    role: string | null
    isActive: boolean
}

export const getTokenInfo = (): AuthStateIT => {
    const token = localStorage.getItem('token')
    if (token) {
        const decoded: any = jwt_decode(token)
        localStorage.setItem('token', token)

        return {
            _id: decoded?._id,
            email: decoded?.email,
            username: decoded?.username,
            name: decoded?.name,
            lastName: decoded?.lastName,
            role: decoded?.role,
            isActive: decoded?.isActive,
        }
    }
    return {
        _id: null,
        email: '',
        username: '',
        name: '',
        lastName: '',
        role: null,
        isActive: false,
    }
}
