import React, { Dispatch, SetStateAction, useEffect } from 'react'
import styled from 'styled-components'
import { useTable, useSortBy, Column } from 'react-table'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import Pagination from './Paginations'

interface TableIT {
    columns: Column<object>[]
    data: readonly object[]
    canPreviousPage: boolean
    canNextPage: boolean
    setPageSize: Dispatch<SetStateAction<number>>
    pageSize: number
    currentPage: number
    setCurrentPage: Dispatch<SetStateAction<number>>
    totalPages: number
}

const TableWrapper = styled('div')`
    ${({ theme }) => `
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        overflow-x: auto;
    `}
`

const TableStyled = styled('table')`
    ${({ theme }) => `
        table-layout: auto;
        width: 100%;  
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        font-size: ${theme?.fontSizes?.font16};
        border-radius: 5px;
        th {
            text-align: left;
            background-color: #459b87;
            color: white;
        }
        td, th{
            border: 1px solid #ddd;
            padding: 6px;
        }

        tr:nth-child(even){
            background-color: #f2f2f2;
        }
        tr:hover {
            background-color: #ddd;
        }
    `}
`

const TableResults = styled('div')`
    ${({ theme }) => `
        color: ${theme.colors.black};
        margin-bottom: ${theme?.spacing?.spc20};
        `}
`

const NoContentWrapper = styled('div')`
    ${({ theme }) => `
        background: ${theme.colors.white};
        color: ${theme.colors.darkGreen};
        font-family: Arial, Helvetica, sans-serif;
        height: 6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: ${theme?.fontSizes?.font16};
    `}
`

interface ColumnHeaderProps {
    width?: number
}

const ColumnHeader = styled('div')<Pick<ColumnHeaderProps, 'width'>>`
    ${({ theme, width }) => `
       display: flex;
       
       svg{
        font-size: ${theme?.fontSizes?.font18};
       }

       ${width ? `width: ${width}px;` : ''}
       min-width: 20px;
    `}
`

const TableComp = ({
    columns,
    data,
    canPreviousPage,
    canNextPage,
    setPageSize,
    currentPage,
    setCurrentPage,
    pageSize,
    totalPages,
}: TableIT) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable(
            {
                columns,
                data,
            },
            useSortBy
        )

    // Use Effects
    useEffect(() => {
        if (currentPage > totalPages) {
            setCurrentPage(1)
        }
    }, [currentPage, setCurrentPage, totalPages])

    // Methods
    const previousPage = () => {
        setCurrentPage((currentPage) => currentPage - 1)
    }

    const nextPage = () => {
        setCurrentPage((currentPage) => currentPage + 1)
    }

    return rows?.length > 0 ? (
        <>
            <TableWrapper>
                <TableResults> Total de {rows?.length} resultados</TableResults>
                <TableStyled {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup: any) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(
                                    (column: any, index: number) => (
                                        <th
                                            {...column.getSortByToggleProps()}
                                            key={index}
                                        >
                                            <ColumnHeader width={column?.width}>
                                                {column.render('Header')}

                                                {!column?.noSort &&
                                                column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <ArrowDownwardIcon />
                                                    ) : (
                                                        <ArrowUpwardIcon />
                                                    )
                                                ) : (
                                                    ''
                                                )}
                                            </ColumnHeader>
                                        </th>
                                    )
                                )}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows
                            .slice(
                                (currentPage - 1) * pageSize,
                                currentPage * pageSize
                            )
                            .map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()} key={i}>
                                        {row.cells.map(
                                            (cell, index: number) => {
                                                return (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        key={index}
                                                    >
                                                        {cell.render('Cell')}
                                                    </td>
                                                )
                                            }
                                        )}
                                    </tr>
                                )
                            })}
                    </tbody>
                </TableStyled>
            </TableWrapper>
            <Pagination
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                nextPage={nextPage}
                previousPage={previousPage}
                setPageSize={setPageSize}
                pageSize={pageSize}
                currentPage={currentPage}
                totalPages={totalPages}
            />
        </>
    ) : (
        <NoContentWrapper>Aún no hay datos para mostrar</NoContentWrapper>
    )
}

export default TableComp
