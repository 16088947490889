export const servicesOptions = [
    {
        label: 'Recepcionista',
        value: 'Recepcionista',
    },

    {
        label: 'Sala de rayos',
        value: 'Sala de rayos',
    },

    {
        label: 'Pabellón de cirugía',
        value: 'Pabellón de cirugía',
    },

    {
        label: 'Cocina',
        value: 'Cocina',
    },
]

export const parkingOptions = [
    {
        label: 'Estacionamiento dentistas - Pagado',
        value: 'Estacionamiento dentistas - Pagado',
    },
    {
        label: 'Estacionamiento dentistas - Gratis',
        value: 'Estacionamiento dentistas - Gratis',
    },
    {
        label: 'Sin estacionamiento',
        value: 'Sin estacionamiento',
    },
    {
        label: 'Estacionamiento paciente - Pagado',
        value: 'Estacionamiento paciente - Pagado',
    },
    {
        label: 'Estacionamiento paciente - Gratis',
        value: 'Estacionamiento paciente - Gratis',
    },
    {
        label: 'Cocina',
        value: 'Cocina',
    },
]

export const materialsOptions = [
    {
        label: 'Toalla de papel',
        value: 'Toalla de papel',
    },

    {
        label: 'Vasito',
        value: 'Vasito',
    },

    {
        label: 'Ejector desechable',
        value: 'Ejector desechable',
    },
    {
        label: 'Jabón',
        value: 'Jabón',
    },
    {
        label: 'Guantes',
        value: 'Guantes',
    },
    {
        label: 'Pechera',
        value: 'Pechera',
    },
    {
        label: 'Gorro desechable',
        value: 'Gorro desechable',
    },
    {
        label: 'Mascarilla',
        value: 'Mascarilla',
    },
    {
        label: 'Lámpara fotocurado',
        value: 'Lámpara fotocurado',
    },
    {
        label: 'Ultrasonido',
        value: 'Ultrasonido',
    },
    {
        label: 'Lentes de protecctión',
        value: 'Lentes de protecctión',
    },
    {
        label: 'Instrumental rotatorio',
        value: 'Instrumental rotatorio',
    },
]
