import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

// Routes Register
import Login from '../containers/Login'
import PageNotFound from '../containers/PageNotFound'
import Boxes from '../containers/Boxes'
import AddEditBox from '../containers/Boxes/AddEditBox'
import Users from '../containers/Users'
import AddEditUser from '../containers/Users/AddEditUser'
import CreateNewPassword from '../containers/CreateNewPassword'
import RecoveryPassword from '../containers/RecoveryPassword'

const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route
                    path="/auth/forgot-password"
                    element={<RecoveryPassword />}
                />
                <Route
                    path="/auth/create-password/:token"
                    element={<CreateNewPassword />}
                />
                <Route path="/dashboard/boxes" element={<Boxes />} />
                <Route path="/dashboard/boxes/:id" element={<AddEditBox />} />
                <Route path="/dashboard/users" element={<Users />} />
                <Route path="/dashboard/users/:id" element={<AddEditUser />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </Router>
    )
}

export default AppRouter
