import { toast } from 'react-toastify'
import {
    TOAST_CHANGE_ERRROR,
    TOAST_CHANGE_INFO,
    TOAST_CHANGE_SUCCESS,
    TOAST_CHANGE_WARNING,
    TOAST_RESET,
} from './types'

interface ToastStateIT {
    message: string
}

const initialState: ToastStateIT = {
    message: '',
}

const toastReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case TOAST_CHANGE_SUCCESS:
            toast.success(action.message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })

            return {
                ...state,
                message: action.message,
            }
        case TOAST_CHANGE_WARNING:
            toast.warning(action.message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })

            return {
                ...state,
                message: action.message,
            }
        case TOAST_CHANGE_ERRROR:
            toast.error(action.message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })

            return {
                ...state,
                message: action.message,
            }
        case TOAST_CHANGE_INFO:
            toast.info(action.message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })

            return {
                ...state,
                message: action.message,
            }
        case TOAST_RESET:
            return {
                ...state,
                message: initialState.message,
            }
        default:
            return {
                ...state,
            }
    }
}

export default toastReducer
