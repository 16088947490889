// Modules
import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Typography } from '@mui/material'

// Interfaces
import { BreadcrumbItemProps } from './interface'

const LinkBreadcrumb = styled(Link)`
    ${({ theme }) => `
        color: ${theme?.colors?.black};
        text-decoration: none;
        
        &:hover{
            color: ${theme?.colors?.darkGreen};
            text-decoration: underline;
        }
    `}
`

function BreadcrumbItem({ text, link }: BreadcrumbItemProps) {
    return link ? (
        <LinkBreadcrumb to={link}>{text}</LinkBreadcrumb>
    ) : (
        <Typography color={'#003F37'} style={{ fontWeight: 'bold' }}>
            {text}
        </Typography>
    )
}

export default BreadcrumbItem
