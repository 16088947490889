import React, { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Grid } from '@mui/material'

interface PaginationIT {
    canPreviousPage: boolean
    canNextPage: boolean
    nextPage: () => void
    previousPage: () => void
    setPageSize: Dispatch<SetStateAction<number>>
    pageSize: number
    currentPage: number
    totalPages: number
}

const PaginationWrapper = styled('div')`
    ${({ theme }) => `
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: ${theme?.spacing?.spc35};
        padding: 0 0 0 ${theme?.spacing?.spc20};
    `}
`

const ContentWrapper = styled('div')`
    ${({ theme }) => `
        width: 100%;
        display: flex;
        align-items:center;

        @media screen and (max-width: 768px){
           justify-content: center;
           margin-top: ${theme?.spacing?.spc10};
       }
    `}
`

const TextPagination = styled('span')`
    ${({ theme }) => `
       font-size: ${theme?.fontSizes?.font16};
       margin-right: ${theme?.spacing?.spc10};
    `}
`

const NavigationButton = styled('button')`
    ${({ theme }) => `
        display: flex;
        align-items: center;
        justify-content:center;
        background: ${theme.colors.middleGreen};
        color: ${theme.colors.white};
        border-radius: ${theme?.spacing?.spc6};
        margin-left: ${theme?.spacing?.spc6};
        border: none;
        padding: ${theme?.spacing?.spc8};
        cursor: pointer;

        &:hover{
            background: ${theme?.colors?.darkGreen};
        }

        :disabled{
            background: ${theme?.colors?.greyBase};
            cursor: not-allowed;
        }
        
        svg{
            font-size: ${theme?.fontSizes?.font14};
        }
    `}
`

const SelectStyled = styled('select')`
    ${({ theme }) => `
        display: flex;
        align-items: center;
        justify-content:center;
        background: ${theme.colors.white};
        color: ${theme.colors.black};
        border-radius: ${theme?.spacing?.spc10};
        padding: ${theme?.spacing?.spc8};
        font-size: ${theme?.fontSizes?.font16};
        outline: none;
        cursor: pointer;
    `}
`

const Pagination = ({
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    currentPage,
    pageSize,
    totalPages,
}: PaginationIT) => {
    return (
        <PaginationWrapper>
            <Grid container spacing={2}>
                <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
                    <ContentWrapper>
                        <TextPagination>
                            {`${currentPage} - ${totalPages} de páginas`}
                        </TextPagination>
                    </ContentWrapper>
                </Grid>
                <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
                    {canPreviousPage || canNextPage ? (
                        <ContentWrapper style={{ justifyContent: 'center' }}>
                            <NavigationButton
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                            >
                                <ArrowBackIosIcon />
                            </NavigationButton>
                            <NavigationButton
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                            >
                                <ArrowForwardIosIcon />
                            </NavigationButton>
                        </ContentWrapper>
                    ) : null}
                </Grid>
                <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
                    <ContentWrapper style={{ justifyContent: 'flex-end' }}>
                        <TextPagination>Líneas por página:: </TextPagination>
                        <SelectStyled
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value))
                            }}
                        >
                            {[10, 50, 100].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </SelectStyled>
                    </ContentWrapper>
                </Grid>
            </Grid>
        </PaginationWrapper>
    )
}

export default Pagination
