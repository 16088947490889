import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'

interface SelectProps {
    options: any
    defaultValue: any
    setFieldValue: any
    fieldName: any
    fieldValue: any
    placeholder: any
}

const SelectWrapper = styled('div')`
    ${({ theme }) => `
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: ${theme?.spacing?.spc10};
        
        .select-control-class{
            & > div{
                background:${theme?.colors?.grey};
                border:none;
                border-radius:${theme?.spacing?.spc10};
                padding: 0;

                & > div{
                    padding: 0;
                    margin: 0;
                }
            }
            
        }
    `}
`

const SelectComp = ({
    defaultValue,
    options,
    setFieldValue,
    fieldName,
    fieldValue,
    placeholder,
}: SelectProps) => {
    const onChange = (selectedOptions: any) => {
        setFieldValue(fieldName, selectedOptions)
    }

    return (
        <SelectWrapper>
            <Select
                className="select-control-class"
                closeMenuOnSelect={false}
                defaultValue={defaultValue}
                isMulti
                options={options}
                name={fieldName}
                value={fieldValue}
                placeholder={placeholder}
                onChange={onChange}
            />
        </SelectWrapper>
    )
}

export default SelectComp
