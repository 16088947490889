import { ThemeProvider } from 'styled-components'
import { theme, GlobalStyle } from './styles/theme'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AppRouter from './routes/AppRouter'
import { Provider } from 'urql'
import { client } from './graphql'

function App() {
    return (
        <ThemeProvider theme={theme}>
            <ToastContainer limit={4} />
            <GlobalStyle />
            <Provider value={client}>
                <AppRouter />
            </Provider>
        </ThemeProvider>
    )
}

export default App
