import React from 'react'
import { Stack } from '@mui/material'
import Button from '@mui/material/Button'
import Modal from 'react-modal'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import {
    TOAST_CHANGE_ERRROR,
    TOAST_CHANGE_SUCCESS,
} from '../../../redux/toast/types'
import { DeleteUserIT } from './interface'
import { deleteUser } from '../../../services/auth'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
}

const ModalContent = styled('div')`
    ${({ theme }) => `
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        background: ${theme.colors.white};
        border-radius:${theme?.spacing?.spc10};
        padding: ${theme?.spacing?.spc20};
    `}
`

const ModalMessage = styled('div')`
    ${({ theme }) => `
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: ${theme.colors.white};
        border-radius:${theme?.spacing?.spc10};
        margin-top:${theme?.spacing?.spc20};
        padding: ${theme?.spacing?.spc20};
    `}
`

const ModalTitle = styled('h3')`
    ${({ theme }) => `
        font-family: raleway;
        color: ${theme.colors.darkBlue};
        margin: 0;
    `}
`

const ModalActions = styled('div')`
    ${({ theme }) => `
        font-family: raleway;
        color: ${theme.colors.darkBlue};
        margin-top: {theme?.spacing?.spc20};
        flex-wrap: wrap;
    `}
`

const DeleteUser = ({ isOpen, setIsOpen, id, setRefreshKey }: DeleteUserIT) => {
    // Hooks
    const dispatch = useDispatch()

    // Methods
    const afterOpenModal = () => {}

    const closeModal = () => {
        setIsOpen(false)
    }

    const handleDelete = (userId: string) => {
        deleteUser(userId)
            .then((resp: any) => {
                dispatch({
                    type: TOAST_CHANGE_SUCCESS,
                    message: '¡Usuario eliminado correctamente!',
                })
                setRefreshKey((refreshKey) => refreshKey + 1)
                closeModal()
            })
            .catch((err: any) => {
                dispatch({
                    type: TOAST_CHANGE_ERRROR,
                    message: 'Error al eliminar al usuario',
                })
            })
    }

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <ModalContent>
                    <ModalMessage>
                        <ModalTitle>
                            ¿Realmente le gustaría borrar a este usuario?
                        </ModalTitle>
                    </ModalMessage>
                    <ModalActions>
                        <Stack spacing={2} direction="row">
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => handleDelete(id)}
                            >
                                Borrar
                            </Button>
                            <Button variant="outlined" onClick={closeModal}>
                                Cancelar
                            </Button>
                        </Stack>
                    </ModalActions>
                </ModalContent>
            </Modal>
        </div>
    )
}

export default DeleteUser
