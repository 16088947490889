import api from '../axios'

export const loginUser = (email: string, password: string) => {
    return api.post('/login', { email, password })
}

export const forgetPassword = (email: string) => {
    return api.post('/recovery-password', { email })
}

export const createNewPasssword = (
    password: string,
    token: string | undefined
) => {
    return api.post(
        '/create-password',
        { password },
        {
            headers: {
                Authorization: 'Bearer ' + token, //the token is a variable which holds the token
            },
        }
    )
}

export const getAllUsers = () => {
    return api.get('/user')
}

export const getUserById = (id: string) => {
    return api.get(`/user/getById/${id}`)
}

export const addUser = (data: any) => {
    return api.post(`/user/add`, data)
}

export const updateUser = (id: string, data: any) => {
    return api.put(`/user/${id}`, data)
}

export const deleteUser = (id: string) => {
    return api.delete(`/user/${id}`)
}
