// Modules
import React, { useEffect, useState } from 'react'
import { FormikHelpers } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '@mui/material'

// Components
import { BreadcrumbItem, Panel } from '../../../components'
import Dashboard from '../../Dashboard'

// Forms
import AddEditBoxForm from '../../../Forms/AddEditBoxForm'

// Services
import { createBox, getBoxById, updateBox } from '../../../services/boxes'

// Interfaces
import { FormValues } from './interface'
import { useDispatch } from 'react-redux'
import { toastErro, toastSuccess } from '../../../redux/toast/actions'

const AddEditBox = () => {
    // States
    const [isLoading, setIsLoading] = useState(false)
    const [initialValues, setInitialValues] = useState({
        firstImage: {},
        secondImage: {},
        thirdImage: {},
        name: '',
        slug: '',
        boxSize: 0,
        address: '',
        documentation: {},
        materials: [],
        services: [],
        parking: [],
        isActive: false,
    })
    const [position, setPosition] = useState({})

    // Routes
    const { id: boxId } = useParams()

    // Hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // Use Effects
    const mappingLabels = (arrValue: Array<string>) => {
        const newArr: any = []
        arrValue?.forEach((item) => {
            newArr.push({
                label: item,
                value: item,
            })
        })
        return newArr
    }

    const mappingStrings = (arrValue: Array<any>) => {
        const newArr: any = []
        arrValue?.forEach((item) => {
            newArr.push(item.value)
        })
        return newArr
    }

    useEffect(() => {
        if (boxId && boxId !== 'addBox') {
            setIsLoading(true)
            getBoxById(boxId)
                .then((resp: any) => {
                    setPosition(JSON.parse(resp?.data?.data?.address)?.position)
                    setInitialValues({
                        firstImage: resp?.data?.data?.images[0],
                        secondImage: resp?.data?.data?.images[1],
                        thirdImage: resp?.data?.data?.images[2],
                        name: resp?.data?.data?.name,
                        slug: resp?.data?.data?.slug,
                        boxSize: resp?.data?.data?.boxSize,
                        documentation: {},
                        address: resp?.data?.data?.address,
                        materials: mappingLabels(resp?.data?.data?.materials),
                        services: mappingLabels(resp?.data?.data?.services),
                        parking: mappingLabels(
                            resp?.data?.data?.parkingDentist
                        ),
                        isActive: resp?.data?.data?.isActive,
                    })
                    setIsLoading(false)
                })
                .catch((err: any) => {
                    dispatch(toastErro('Error para encontrar tu box!'))
                    setIsLoading(false)
                })
        }
    }, [boxId, dispatch, navigate])

    // Methods
    const handleSubmit = (
        values: FormValues,
        actions: FormikHelpers<FormValues>
    ) => {
        if (boxId === 'addBox') {
            setIsLoading(true)
            createBox(
                values.firstImage,
                values.secondImage,
                values.thirdImage,
                values.name,
                values.slug,
                values.boxSize,
                values.isActive,
                mappingStrings(values.services),
                mappingStrings(values.parking),
                mappingStrings(values.materials),
                values.address
            )
                .then((resp: any) => {
                    dispatch(toastSuccess('Box actualizado con éxito!'))
                    navigate('/dashboard/boxes')
                    setIsLoading(false)
                })
                .catch((err: any) => {
                    dispatch(toastErro('Erro ao actualizat box!'))
                    setIsLoading(false)
                })
        } else {
            setIsLoading(true)
            updateBox(
                boxId || '',
                values.name,
                values.slug,
                values.boxSize,
                values.isActive,
                mappingStrings(values.services),
                mappingStrings(values.parking),
                mappingStrings(values.materials),
                values.address
            )
                .then((resp: any) => {
                    dispatch(toastSuccess('Box actualizado con éxito!'))
                    setIsLoading(false)
                    navigate('/dashboard/boxes')
                })
                .catch((err: any) => {
                    dispatch(toastErro('Erro ao actualizat box!'))
                    setIsLoading(false)
                })
        }
        actions.setSubmitting(false)
    }

    const breadcrumbs = [
        <BreadcrumbItem text={'Boxes'} link={'/dashboard/boxes'} />,
        <BreadcrumbItem
            text={boxId === 'addBox' ? 'Agregar box' : 'Editar box'}
        />,
    ]

    return (
        <Dashboard>
            <>
                <Panel
                    title={boxId === 'addBox' ? 'Agregar box' : 'Editar box'}
                    isLoading={isLoading}
                    breadcrumbs={breadcrumbs}
                >
                    <AddEditBoxForm
                        handleSubmit={handleSubmit}
                        initialValues={initialValues}
                        position={position}
                    />
                </Panel>
                <Button
                    onClick={() => navigate('/dashboard/boxes')}
                    variant="outlined"
                    style={{
                        background: '#ffff',
                        borderColor: '#0b1843',
                        color: '#0b1843',
                        marginTop: '20px',
                    }}
                >
                    Volver
                </Button>
            </>
        </Dashboard>
    )
}

export default AddEditBox
