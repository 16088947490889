import api from '../axios'

export const getAllBoxes = () => {
    return api.get('/box')
}

export const getBoxById = (id: string) => {
    return api.get(`/box/${id}`)
}

export const deleteBox = (id: string) => {
    return api.delete(`/box/${id}`)
}

export const createBox = (
    firstImage: any,
    secondImage: any,
    thirdImage: any,
    name: string,
    slug: string,
    boxSize: number,
    isActive: boolean | any,
    services: Array<string> | any,
    parking: Array<string> | any,
    materials: Array<string> | any,
    address: string
) => {
    const formData = new FormData()
    formData.append('name', name)
    formData.append('slug', slug)
    formData.append('boxSize', boxSize.toString())
    formData.append('isActive', isActive.toString())
    formData.append('services', JSON.stringify(services))
    formData.append('parking', JSON.stringify(parking))
    formData.append('materials', JSON.stringify(materials))
    formData.append('address', address)
    formData.append('file', firstImage)
    formData.append('file', secondImage)
    formData.append('file', thirdImage)

    return api.post('/box/admin', formData, {
        headers: {
            'Content-Type': `multipart/form-data`,
        },
    })
}

export const updateBox = (
    id: string,
    name: string,
    slug: string,
    boxSize: number,
    isActive: boolean,
    services: Array<string>,
    parkingDentist: Array<string>,
    materials: Array<string>,
    address: string
) => {
    const data = {
        name,
        slug,
        boxSize,
        isActive,
        services,
        parkingDentist,
        materials,
        address,
    }
    return api.put(`/box/admin/${id}`, data)
}
