import React from 'react'
import styled from 'styled-components'
import { Formik, Form, Field, FormikProps } from 'formik'
import * as Yup from 'yup'
import { Grid, Switch } from '@mui/material'

// Types
import { FormValues, AddEditUserProps, OtherProps } from './interface'
import { Button } from '@mui/material'

const FormWrapper = styled('div')`
    ${({ theme }) => `
        width:100%;
        display:flex;
        align-items:left;
        flex-direction:column;

        form{
            width:90%;
            margin-left: 5%;
        }
  `}
`

const FormControl = styled('div')`
    ${({ theme }) => `
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top:${theme?.spacing?.spc18};
        
        input,select{
            width: 100%;
            height:2.5rem;
            background:${theme?.colors?.grey};
            border:none;
            border-radius:${theme?.spacing?.spc10};
        }
    `}
`

const FormControlWrapper = styled('div')`
    ${({ theme }) => `
        width: 100%;
        display: flex;
        flex-direction: column;
    `}
`

const ErrorDiv = styled('div')`
    ${({ theme }) => `
        color:${theme?.colors?.red};
        font-size:${theme?.fontSizes?.font14};
        font-family:Arial, Helvetica, sans-serif;
        margin-top:${theme?.spacing?.spc6};
    `}
`

const Label = styled('label')`
    ${({ theme }) => `
        font-size:${theme?.fontSizes?.font16};
        font-weight: bold;
        font-family:Arial, Helvetica, sans-serif;
        width: 200px;
        color:${theme?.colors?.darkGreen};
        margin-bottom:${theme?.spacing?.spc10};
    `}
`

const validation = Yup.object({
    name: Yup.string()
        .max(45, 'Nombre demasiado largo')
        .required('Campo obligatório'),
    username: Yup.string()
        .max(45, 'Username demasiado largo')
        .required('Campo obligatório'),
    email: Yup.string()
        .max(45, 'Correo demasiado largo')
        .email('Introduzca un correo electrónico válido')
        .required('Campo obligatório'),
    phone: Yup.string()
        .matches(
            // eslint-disable-next-line no-useless-escape
            /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/,
            'Telefone inválido'
        )
        .required('Campo obligatório'),
    role: Yup.string().required('Elija un papel'),
    passwd: Yup.string()
        .min(3, 'Contraseña demasiado curta')
        .max(45, 'Contraseña demasiado larga')
        .required('Campo obligatório'),
    confirmPassword: Yup.string()
        .min(3, 'Contraseña demasiado curta')
        .oneOf([Yup.ref('passwd'), null], 'As contraseñas no coinciden')
        .required('Campo obligatório'),
})

const AddEditBox = ({ handleSubmit, initialValues }: AddEditUserProps) => {
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => handleSubmit(values, actions)}
            validationSchema={validation}
            enableReinitialize={true}
        >
            {(props: OtherProps & FormikProps<FormValues>) => {
                const { touched, errors } = props
                return (
                    <FormWrapper>
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <FormControl>
                                        <Label htmlFor="name">Nombre *:</Label>
                                        <FormControlWrapper>
                                            <Field id="name" name="name" />
                                            {touched.name && errors.name && (
                                                <ErrorDiv>
                                                    {errors.name}
                                                </ErrorDiv>
                                            )}
                                        </FormControlWrapper>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <FormControl>
                                        <Label htmlFor="username">
                                            Username *:
                                        </Label>
                                        <FormControlWrapper>
                                            <Field
                                                id="username"
                                                name="username"
                                            />
                                            {touched.username &&
                                                errors.username && (
                                                    <ErrorDiv>
                                                        {errors.username}
                                                    </ErrorDiv>
                                                )}
                                        </FormControlWrapper>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <FormControl>
                                        <Label htmlFor="email">Correo *:</Label>
                                        <FormControlWrapper>
                                            <Field id="email" name="email" />
                                            {touched.email && errors.email && (
                                                <ErrorDiv>
                                                    {errors.email}
                                                </ErrorDiv>
                                            )}
                                        </FormControlWrapper>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <FormControl>
                                        <Label htmlFor="phone">
                                            Teléfono *:
                                        </Label>
                                        <FormControlWrapper>
                                            <Field id="phone" name="phone" />
                                            {touched.phone && errors.phone && (
                                                <ErrorDiv>
                                                    {errors.phone}
                                                </ErrorDiv>
                                            )}
                                        </FormControlWrapper>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <FormControl>
                                        <Label htmlFor="email">
                                            Funcción *:
                                        </Label>
                                        <FormControlWrapper>
                                            <Field as="select" name="role">
                                                <option
                                                    value=""
                                                    selected
                                                    disabled
                                                    hidden
                                                >
                                                    Eijar una funcción...
                                                </option>
                                                <option value="ADMIN">
                                                    ADMIN
                                                </option>
                                                <option value="CLIENT">
                                                    CLIENT
                                                </option>
                                                <option value="DENTIST">
                                                    DENTIST
                                                </option>
                                            </Field>
                                            {touched.role && errors.role && (
                                                <ErrorDiv>
                                                    {errors.role}
                                                </ErrorDiv>
                                            )}
                                        </FormControlWrapper>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <FormControl>
                                        <Label htmlFor="passwd">
                                            Contraseña *:
                                        </Label>
                                        <FormControlWrapper>
                                            <Field
                                                id="passwd"
                                                name="passwd"
                                                type="password"
                                            />
                                            {touched.passwd &&
                                                errors.passwd && (
                                                    <ErrorDiv>
                                                        {errors.passwd}
                                                    </ErrorDiv>
                                                )}
                                        </FormControlWrapper>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <FormControl>
                                        <Label htmlFor="confirmPassword">
                                            Confirmar contraseña *:
                                        </Label>
                                        <FormControlWrapper>
                                            <Field
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                type="password"
                                            />
                                            {touched.confirmPassword &&
                                                errors.confirmPassword && (
                                                    <ErrorDiv>
                                                        {errors.confirmPassword}
                                                    </ErrorDiv>
                                                )}
                                        </FormControlWrapper>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <FormControl>
                                        <Label htmlFor="isActive">
                                            Activo:
                                        </Label>
                                        <FormControlWrapper>
                                            <Switch
                                                id="isActive"
                                                name="isActive"
                                                onChange={(e: any) =>
                                                    props.setFieldValue(
                                                        'isActive',
                                                        e?.target?.checked
                                                    )
                                                }
                                                checked={
                                                    props.values['isActive']
                                                }
                                                inputProps={{
                                                    'aria-label': 'controlled',
                                                }}
                                            />
                                        </FormControlWrapper>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <FormControl style={{ alignItems: 'flex-end' }}>
                                <Button
                                    variant="outlined"
                                    style={{
                                        background: '#008574',
                                        color: '#fff',
                                        marginTop: '20px',
                                        width: '10rem',
                                    }}
                                    type="submit"
                                >
                                    Guardar
                                </Button>
                            </FormControl>
                        </Form>
                    </FormWrapper>
                )
            }}
        </Formik>
    )
}

export default AddEditBox
