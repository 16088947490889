import { Button } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import Dashboard from '../Dashboard'

// Routes
import { useNavigate } from 'react-router-dom'

const NotFoundWrapper = styled('span')`
    ${({ theme }) => `
        display: flex;
        align-items:center;
        justify-content: center;
        cursor: pointer;
        flex-direction: column;
        font-size:${theme?.fontSizes?.font24};
        padding:${theme?.spacing?.spc30} 0;
        background: ${theme?.colors?.white};
    `}
`
const TitlePageNotFound = styled('span')`
    ${({ theme }) => `
        font-family: raleway;
        font-weight: bold;
        color: ${theme?.colors?.darkBlue};
        margin-top: ${theme?.spacing?.spc30};
        margin-bottom: ${theme?.spacing?.spc30};
    `}
`

const PageNotFound = () => {
    const navigate = useNavigate()
    return (
        <Dashboard>
            <NotFoundWrapper>
                <TitlePageNotFound>Página nāo encontrada</TitlePageNotFound>
                <Button
                    onClick={() => navigate('/dashboard/users')}
                    variant="outlined"
                    style={{
                        background: '#0b1843',
                        color: '#fff',
                    }}
                >
                    Volver a página inicial
                </Button>
            </NotFoundWrapper>
        </Dashboard>
    )
}

export default PageNotFound
