import { createClient } from 'urql'

const baseUrl: string = process.env.REACT_APP_API || 'http://localhost:3333'

export const client = createClient({
    url: baseUrl,
    fetchOptions: () => {
        const token = localStorage.getItem('token')
        return {
            headers: { authorization: token ? `Bearer ${token}` : '' },
        }
    },
})
