import React from 'react'
import styled from 'styled-components'
import { PanelProps } from './interface'
import { Breadcrumbs, Grid } from '@mui/material'
import Loading from '../Loading/Loading'

// Icons
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

const PanelWrapper = styled('div')`
    ${({ theme }) => `
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: ${theme.colors.white};
        border-radius:${theme?.spacing?.spc10};
        margin-top:${theme?.spacing?.spc20};
        padding: ${theme?.spacing?.spc20};

        @media screen and (max-width: 375px){
            overflow-x: auto;
            max-width: 250px;
        }
    `}
`

const PanelTitle = styled('span')`
    ${({ theme }) => `
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        color: ${theme.colors.darkGreen};
        margin: 0;
        font-size:${theme?.fontSizes?.font26};

        @media screen and (max-width: 425px){
            font-size:${theme?.fontSizes?.font16};
        }
    `}
`

const ButtonsDiv = styled('div')`
    ${({ theme }) => `
        display: flex;
        align-items: center;
        justify-content: flex-end;
    `}
`

const Panel = ({
    title,
    buttons,
    children,
    isLoading = false,
    breadcrumbs,
}: PanelProps) => {
    return (
        <>
            {breadcrumbs ? (
                <Grid
                    container
                    spacing={2}
                    style={{ marginBottom: '1.5rem', marginTop: '0rem' }}
                >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Breadcrumbs
                            separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb"
                        >
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            ) : null}
            <PanelWrapper>
                {isLoading ? <Loading /> : null}
                <Grid
                    container
                    spacing={2}
                    style={{
                        marginBottom: '1rem',
                        paddingBottom: '1rem',
                    }}
                >
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <PanelTitle>{title}</PanelTitle>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <ButtonsDiv>
                            {buttons &&
                                buttons.map((button: JSX.Element) => button)}
                        </ButtonsDiv>
                    </Grid>
                </Grid>
                {children}
            </PanelWrapper>
        </>
    )
}

export default Panel
