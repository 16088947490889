import {
    TOAST_CHANGE_SUCCESS,
    TOAST_CHANGE_WARNING,
    TOAST_CHANGE_ERRROR,
    TOAST_CHANGE_INFO,
    TOAST_RESET,
} from './types'

export const toastSuccess = (message: string) => {
    return { type: TOAST_CHANGE_SUCCESS, message }
}

export const toastWarning = (message: string) => {
    return { type: TOAST_CHANGE_WARNING, message }
}

export const toastErro = (message: string) => {
    return { type: TOAST_CHANGE_ERRROR, message }
}

export const toastInfo = (message: string) => {
    return { type: TOAST_CHANGE_INFO, message }
}

export const toastReset = (message: string) => {
    return { type: TOAST_RESET }
}
