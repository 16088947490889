import jwt_decode from 'jwt-decode'

export const isAuthenticated = () => {
    const token: string | null = localStorage.getItem('token')

    if (token) {
        const decoded: any = jwt_decode(token)
        if (decoded?.exp < (new Date().getTime() + 1) / 1000) {
            return false
        }
        return true
    }
    return false
}
