import { AuthStateIT, getTokenInfo } from './getTokenInfo'
import { LOGIN, LOGOUT } from './types'

const initialState: AuthStateIT = getTokenInfo()

const authReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LOGIN:
            return {
                _id: action?.payload?._id,
                email: action?.payload?.email,
                name: action?.payload?.name,
                lastName: action?.payload?.lastName,
                role: action?.payload.role,
                isActive: action?.payload?.isActive,
            }
        case LOGOUT:
            return {
                _id: null,
                email: '',
                username: '',
                name: '',
                lastName: '',
                role: null,
                isActive: false,
            }
        default:
            return {
                ...state,
            }
    }
}

export default authReducer
