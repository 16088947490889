import React from 'react'
import styled from 'styled-components'
import { Formik, Form, Field, FormikProps } from 'formik'
import * as Yup from 'yup'
import { Grid } from '@mui/material'
import Switch from '@mui/material/Switch'
import { FileUploaderComponent, SelectComp } from '../../components'
import { materialsOptions, parkingOptions, servicesOptions } from './data'

// Types
import { FormValues, AddEditBoxProps, OtherProps } from './interface'
import { Button } from '@mui/material'
import Address from './Address/Address'

const FormWrapper = styled('div')`
    ${({ theme }) => `
        width:100%;
        display:flex;
        align-items:left;
        flex-direction:column;

        form{
            width:90%;
            margin-left: 5%;
        }
  `}
`

const FormControl = styled('div')`
    ${({ theme }) => `
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top:${theme?.spacing?.spc18};
        
        input,select{
            width: 100%;
            height:2.5rem;
            background:${theme?.colors?.grey};
            border:none;
            border-radius:${theme?.spacing?.spc10};
        }
    `}
`

const FormControlWrapper = styled('div')`
    ${({ theme }) => `
        width: 100%;
        display: flex;
        flex-direction: column;
    `}
`

const ErrorDiv = styled('div')`
    ${({ theme }) => `
        color:${theme?.colors?.red};
        font-size:${theme?.fontSizes?.font14};
        font-family:Arial, Helvetica, sans-serif;
        margin-top:${theme?.spacing?.spc6};
    `}
`

const Label = styled('label')`
    ${({ theme }) => `
        font-size:${theme?.fontSizes?.font16};
        font-weight: bold;
        font-family:Arial, Helvetica, sans-serif;
        width: 200px;
        color:${theme?.colors?.darkGreen};
        margin-bottom:${theme?.spacing?.spc10};
    `}
`

const validation = Yup.object({
    name: Yup.string()
        .max(45, 'Nombre demasiado largo')
        .required('Campo obligatório'),
    slug: Yup.string()
        .max(100, 'Slug demasiado largo')
        .required('Campo obligatório'),
    boxSize: Yup.number()
        .max(999999999, 'Tamaño demasiado largo')
        .required('Campo obligatório'),
    services: Yup.array().min(1, 'Campo obligatório'),
    materials: Yup.array().min(1, 'Campo obligatório'),
    parking: Yup.array().min(1, 'Campo obligatório'),
    address: Yup.string().required('Campo obligatório'),
})

const AddEditBox = ({
    handleSubmit,
    initialValues,
    position,
}: AddEditBoxProps) => {
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => handleSubmit(values, actions)}
            validationSchema={validation}
            enableReinitialize={true}
        >
            {(props: OtherProps & FormikProps<FormValues>) => {
                const { touched, errors } = props
                return (
                    <FormWrapper>
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <FileUploaderComponent
                                        text={
                                            'Haz clic aquí y sube tu foto (.jpg, .png,.gif)'
                                        }
                                        fieldName={'firstImage'}
                                        fieldValue={props.values['firstImage']}
                                        setField={props.setFieldValue}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <FileUploaderComponent
                                        text={
                                            'Haz clic aquí y sube tu foto (.jpg, .png,.gif)'
                                        }
                                        fieldName={'secondImage'}
                                        fieldValue={props.values['secondImage']}
                                        setField={props.setFieldValue}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <FileUploaderComponent
                                        text={
                                            'Haz clic aquí y sube tu foto (.jpg, .png,.gif)'
                                        }
                                        fieldName={'thirdImage'}
                                        fieldValue={props.values['thirdImage']}
                                        setField={props.setFieldValue}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Grid container spacing={2}>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <FormControl>
                                                <Label htmlFor="name">
                                                    Nombre*:
                                                </Label>
                                                <FormControlWrapper>
                                                    <Field
                                                        id="name"
                                                        name="name"
                                                    />
                                                    {touched.name &&
                                                        errors.name && (
                                                            <ErrorDiv>
                                                                {errors.name}
                                                            </ErrorDiv>
                                                        )}
                                                </FormControlWrapper>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <FormControl>
                                                <Label htmlFor="slug">
                                                    Slug*:
                                                </Label>
                                                <FormControlWrapper>
                                                    <Field
                                                        id="slug"
                                                        name="slug"
                                                    />
                                                    {touched.slug &&
                                                        errors.slug && (
                                                            <ErrorDiv>
                                                                {errors.slug}
                                                            </ErrorDiv>
                                                        )}
                                                </FormControlWrapper>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <FormControl>
                                                <Label htmlFor="boxSize">
                                                    Tamaño(m2)*:
                                                </Label>
                                                <FormControlWrapper>
                                                    <Field
                                                        id="boxSize"
                                                        name="boxSize"
                                                    />
                                                    {touched.boxSize &&
                                                        errors.boxSize && (
                                                            <ErrorDiv>
                                                                {errors.boxSize}
                                                            </ErrorDiv>
                                                        )}
                                                </FormControlWrapper>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <FormControl>
                                                <Label htmlFor="services">
                                                    Servícios*:
                                                </Label>
                                                <FormControlWrapper>
                                                    <SelectComp
                                                        placeholder={
                                                            'Selecciona un servício...'
                                                        }
                                                        defaultValue={[]}
                                                        options={
                                                            servicesOptions
                                                        }
                                                        setFieldValue={
                                                            props.setFieldValue
                                                        }
                                                        fieldName={'services'}
                                                        fieldValue={
                                                            props.values[
                                                                'services'
                                                            ]
                                                        }
                                                    />
                                                    {touched.services &&
                                                        errors.services && (
                                                            <ErrorDiv>
                                                                {
                                                                    errors.services
                                                                }
                                                            </ErrorDiv>
                                                        )}
                                                </FormControlWrapper>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <FormControl>
                                                <Label htmlFor="parking">
                                                    Estacionamientos*:
                                                </Label>
                                                <FormControlWrapper>
                                                    <SelectComp
                                                        placeholder={
                                                            'Selecciona un estacionamiento...'
                                                        }
                                                        defaultValue={[]}
                                                        options={parkingOptions}
                                                        setFieldValue={
                                                            props.setFieldValue
                                                        }
                                                        fieldName={'parking'}
                                                        fieldValue={
                                                            props.values[
                                                                'parking'
                                                            ]
                                                        }
                                                    />
                                                    {touched.parking &&
                                                        errors.parking && (
                                                            <ErrorDiv>
                                                                {errors.parking}
                                                            </ErrorDiv>
                                                        )}
                                                </FormControlWrapper>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <FormControl>
                                                <Label htmlFor="materials">
                                                    Materiales*:
                                                </Label>
                                                <FormControlWrapper>
                                                    <SelectComp
                                                        placeholder={
                                                            'Selecciona un material...'
                                                        }
                                                        defaultValue={[]}
                                                        options={
                                                            materialsOptions
                                                        }
                                                        fieldName={'materials'}
                                                        fieldValue={
                                                            props.values[
                                                                'materials'
                                                            ]
                                                        }
                                                        setFieldValue={
                                                            props.setFieldValue
                                                        }
                                                    />
                                                    {touched.materials &&
                                                        errors.materials && (
                                                            <ErrorDiv>
                                                                {
                                                                    errors.materials
                                                                }
                                                            </ErrorDiv>
                                                        )}
                                                </FormControlWrapper>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={12}>
                                            <FormControl>
                                                <Label htmlFor="isActive">
                                                    Activo:
                                                </Label>
                                                <FormControlWrapper>
                                                    <Switch
                                                        id="isActive"
                                                        name="isActive"
                                                        onChange={(e: any) =>
                                                            props.setFieldValue(
                                                                'isActive',
                                                                e?.target
                                                                    ?.checked
                                                            )
                                                        }
                                                        checked={
                                                            props.values[
                                                                'isActive'
                                                            ]
                                                        }
                                                        inputProps={{
                                                            'aria-label':
                                                                'controlled',
                                                        }}
                                                    />
                                                </FormControlWrapper>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    style={{ marginTop: '20px' }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Label htmlFor="documentation">
                                                Documentación :
                                            </Label>
                                            <FileUploaderComponent
                                                text={
                                                    'Arrastra y suelta tu documentación (.jpg, .png, .pdf, .docx)'
                                                }
                                                fieldName={'documentation'}
                                                fieldValue={
                                                    props.values[
                                                        'documentation'
                                                    ]
                                                }
                                                setField={props.setFieldValue}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            style={{ marginTop: '2rem' }}
                                        >
                                            <Label htmlFor="isActive">
                                                Dirección *:
                                            </Label>

                                            <Address
                                                fieldName={'address'}
                                                setFieldValue={
                                                    props.setFieldValue
                                                }
                                                fieldValue={
                                                    props.values['address']
                                                }
                                                initalPosition={position}
                                            />

                                            {touched.address &&
                                                errors.address && (
                                                    <ErrorDiv>
                                                        {errors.address}
                                                    </ErrorDiv>
                                                )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <FormControl style={{ alignItems: 'flex-end' }}>
                                <Button
                                    variant="outlined"
                                    style={{
                                        background: '#008574',
                                        color: '#fff',
                                        marginTop: '20px',
                                        width: '10rem',
                                    }}
                                    type="submit"
                                >
                                    Guardar
                                </Button>
                            </FormControl>
                        </Form>
                    </FormWrapper>
                )
            }}
        </Formik>
    )
}

export default AddEditBox
