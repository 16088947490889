import { combineReducers } from 'redux'
import authReducer from './auth/reducers'
import toastReducer from './toast/reducers'

const rootReducer = combineReducers({
    toast: toastReducer,
    auth: authReducer,
})

export type RootReducer = ReturnType<typeof rootReducer>
export default rootReducer
