// Modules
import React, { useState } from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'

// Components
import MapsAutoComplete from './PlacesAutocomplete'
const lib: any = ['places']
const mapStyles = {
    height: '20rem',
    width: '100%',
}

function Address({
    fieldName,
    setFieldValue,
    fieldValue,
    initalPosition,
}: any) {
    // States
    const [currentPosition, setCurrentPosition] = useState({
        lat: 0,
        lng: 0,
    })

    // Methods
    const handleChange = (newValue: any) => {
        setFieldValue(fieldName, newValue)
    }

    return (
        <LoadScript
            googleMapsApiKey={'AIzaSyBGnSNtaSmSt6S132pbwEYPmXQthC9xtis'}
            libraries={lib}
        >
            <MapsAutoComplete
                setPosition={setCurrentPosition}
                handleChange={handleChange}
                state={fieldValue}
                setState={setFieldValue}
                setFieldValue={setFieldValue}
                fieldName={fieldName}
                initalPosition={initalPosition}
            />

            <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={13}
                center={currentPosition}
            >
                {currentPosition.lat && <Marker position={currentPosition} />}
            </GoogleMap>
        </LoadScript>
    )
}

export default Address
