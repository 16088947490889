// @ts-nocheck
import { createGlobalStyle } from 'styled-components'
import NormalFont from '../fonts/static/Raleway-Bold.ttf'
import ThinFont from '../fonts/static/Raleway-Thin.ttf'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: #f5f5f5;
    @font-face {
        font-family: "raleway";
        src: url(${NormalFont});
    }
    @font-face {
        font-family: "raleway-thin";
        src: url(${ThinFont});
    }
  }
`

const theme = {
    colors: {
        white: '#fff',
        black: '#000',
        golden: '#ddb665',
        middleGreen: '#21cea4',
        darkGreen: '#003F37',
        waterGreen: '#00FFE0',
        darkBlue: '#0b1843',
        greyBase: '#86858a',
        greyLight: '#CFD7ED',
        fontWhite: '#fff',
        fontBlack: '#000',
        border: '#d8d8d8',
        whiteGrey: '#F8FAFF',
        grey: '#efefef',
        red: '#ff0000',
    },
    spacing: {
        spc2: '0.1rem',
        spc4: '0.2rem',
        spc6: '0.3rem',
        spc8: '0.4rem',
        spc10: '0.5rem',
        spc12: '0.6rem',
        spc14: '0.7rem',
        spc16: '0.8rem',
        spc18: '0.9rem',
        spc20: '1.0rem',
        spc25: '1.2rem',
        spc30: '1.5rem',
        spc35: '2.0rem',
        spc40: '2.5rem',
        spc45: '3.0rem',
        spc50: '3.5rem',
        spc60: '5.5rem',
        spc70: '7.5rem',
        spc80: '10rem',
        spc90: '12rem',
    },
    fontSizes: {
        font8: '0.5rem',
        font9: '0.55rem',
        font10: '0.6rem',
        font12: '0.7rem',
        font14: '0.8rem',
        font16: '0.9rem',
        font18: '1.0rem',
        font20: '1.1rem',
        font22: '1.2rem',
        font24: '1.3rem',
        font26: '1.4rem',
        font27: '1.5rem',
        font30: '2.0rem',
        font35: '2.5rem',
        font40: '3.0rem',
        font45: '3.5rem',
        font50: '5.0rem',
    },
}

export { theme, GlobalStyle }
