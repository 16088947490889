import React, { useEffect, useState } from 'react'
import { FormikHelpers } from 'formik'
import AddEditUserForm from '../../../Forms/AddEditUserForm'
import { FormValues } from './interface'
import Dashboard from '../../Dashboard'
import { useNavigate, useParams } from 'react-router-dom'
import { BreadcrumbItem, Panel } from '../../../components'
import { Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
    TOAST_CHANGE_ERRROR,
    TOAST_CHANGE_SUCCESS,
} from '../../../redux/toast/types'
import { addUser, getUserById, updateUser } from '../../../services/auth'

const AddEditUser = () => {
    // Routes
    const { id: userId } = useParams()

    // States
    const [initialValues, setInitialValues] = useState({
        name: '',
        username: '',
        email: '',
        phone: '',
        passwd: '',
        role: '',
        isActive: false,
        confirmPassword: '',
    })
    const [isLoading, setIsLoading] = useState(false)

    // Redux State
    const state: any = useSelector((state) => state)

    // Hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // Use Effects
    useEffect(() => {
        if (userId !== 'addUser') {
            setIsLoading(true)
            getUserById(userId || '')
                .then((resp: any) => {
                    const userData = resp.data.data
                    setInitialValues({
                        name: userData?.firstName,
                        username: userData?.userName,
                        email: userData?.email,
                        phone: userData?.phone,
                        passwd: '',
                        role: userData?.role,
                        isActive: userData.isActive,
                        confirmPassword: '',
                    })
                    setIsLoading(false)
                })
                .catch((err: any) => {
                    setIsLoading(false)
                })
        }
    }, [dispatch, userId])

    // Methods
    const handleSubmit = (
        values: FormValues,
        actions: FormikHelpers<FormValues>
    ) => {
        if (userId === 'addUser') {
            addUser({
                name: values.name,
                username: values.username,
                email: values.email,
                phone: values.phone,
                role: values.role,
                password: values.passwd,
                isActive: values.isActive,
            })
                .then((result: any) => {
                    dispatch({
                        type: TOAST_CHANGE_SUCCESS,
                        message: '¡Usuario creado con éxito!',
                    })
                    navigate('/dashboard/users')
                })
                .catch((err: any) =>
                    dispatch({
                        type: TOAST_CHANGE_ERRROR,
                        message: '¡Error al crear el usuario!',
                    })
                )
        } else {
            updateUser(userId || '', {
                id: parseInt(userId || '0'),
                name: values.name,
                username: values.username,
                email: values.email,
                phone: values.phone,
                role: values.role,
                password: values.passwd,
                isActive: values.isActive,
            })
                .then((result: any) => {
                    dispatch({
                        type: TOAST_CHANGE_SUCCESS,
                        message: '¡Usuario actualizado correctamente!',
                    })
                    navigate('/dashboard/users')
                })
                .catch((err: any) =>
                    dispatch({
                        type: TOAST_CHANGE_ERRROR,
                        message: '¡Error al actualizar el usuario!',
                    })
                )
        }
        actions.setSubmitting(false)
    }

    const breadcrumbs = [
        <BreadcrumbItem text={'Usuarios'} link={'/dashboard/users'} />,
        <BreadcrumbItem
            text={userId === 'addUser' ? 'Crear usuário' : 'Editar usuário'}
        />,
    ]

    const breadcrumbsUsers = [<BreadcrumbItem text={'Editar usuário'} />]

    return (
        <Dashboard>
            <>
                <Panel
                    title={
                        userId === 'addUser'
                            ? 'Crear usuário'
                            : 'Editar usuário'
                    }
                    isLoading={isLoading}
                    breadcrumbs={
                        state?.auth?.role === 'ADMIN'
                            ? breadcrumbs
                            : breadcrumbsUsers
                    }
                >
                    <AddEditUserForm
                        handleSubmit={handleSubmit}
                        initialValues={initialValues}
                    />
                </Panel>
                {state?.auth?.role === 'ADMIN' ? (
                    <Button
                        onClick={() => navigate('/dashboard/users')}
                        variant="outlined"
                        style={{
                            background: '#ffff',
                            borderColor: '#0b1843',
                            color: '#0b1843',
                            marginTop: '20px',
                        }}
                    >
                        Volver
                    </Button>
                ) : null}
            </>
        </Dashboard>
    )
}

export default AddEditUser
