// Modules
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FormikHelpers } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import jwt_decode, { JwtPayload } from 'jwt-decode'

// Interfaces
import { FormValues } from './interface'

// Forms
import CreateNewPasswordForm from '../../Forms/CreateNewPasswordForm'

// Assets
import FlipitLogo from '../../assets/logo.svg'

// Redux - Actions
import { toastErro, toastSuccess } from '../../redux/toast/actions'

// Services
import { createNewPasssword } from '../../services/auth'

const MainContainer = styled('div')`
    ${({ theme }) => `
      width:100%;
      height:100vh;
      display:flex;
      align-items:center;
      justify-content:center;
      background:#efefef;
    `}
`

const BoxLogin = styled('div')`
    ${({ theme }) => `
      width:100%;
      max-width: 26rem;
      height:30rem;
      display:flex;
      align-items:center;
      flex-direction:column;
      background:${theme?.colors?.white};
      border-radius:${theme?.spacing?.spc10};
      box-shadow: -1px 2px 15px 8px rgba(0,0,0,0.2);
    
      @media screen and (max-width:768px){
        height:30rem;
      }
    `}
`

const TopBox = styled('div')`
    ${({ theme }) => `
        display:flex;
        align-items:center;
        justify-content:center;
        @media screen and (max-width:768px){
            width:100%;
        }
  `}
`
const LogoLogin = styled('img')`
    ${({ theme }) => `
        width: 14rem;
        cursor: pointer;
    `}
`

const Title = styled('span')`
    ${({ theme }) => `
        font-size:${theme?.fontSizes?.font26};
        color:${theme?.colors?.darkBlue};
        margin-top:${theme?.spacing?.spc35};
        font-weight: bold;
        font-family:Arial, Helvetica, sans-serif;
  `}
`

function CreateNewPassword() {
    // Routes
    const { token } = useParams()

    // Hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // Use Effects
    useEffect(() => {
        if (token) {
            try {
                const decodedToken = jwt_decode<JwtPayload>(token)
                const currentDate = Date.now()
                if (decodedToken?.exp) {
                    if (decodedToken?.exp * 1000 < currentDate) {
                        navigate('/')
                    }
                } else {
                    navigate('/')
                }
            } catch (error) {
                navigate('/')
            }
        } else {
            navigate('/')
        }
    }, [navigate, token])

    // Methods
    const handleSubmit = (
        values: FormValues,
        actions: FormikHelpers<FormValues>
    ) => {
        createNewPasssword(values.password, token)
            .then((result: any) => {
                dispatch(
                    toastSuccess('Su contraseña ha sido actualizada con éxito!')
                )
                navigate('/')
            })
            .catch((err: any) => {
                dispatch(toastErro('Error al enviar correo!'))
            })
        actions.setSubmitting(false)
    }

    const initialValues: FormValues = { password: '', confirmPassword: '' }

    return (
        <MainContainer>
            <BoxLogin>
                <TopBox>
                    <LogoLogin
                        src={FlipitLogo}
                        alt="Flipit"
                        onClick={() => navigate('/')}
                    />
                </TopBox>

                <Title>Crear nueva contraseña</Title>
                <CreateNewPasswordForm
                    handleSubmit={handleSubmit}
                    initialValues={initialValues}
                    isLoading={false}
                />
            </BoxLogin>
        </MainContainer>
    )
}

export default CreateNewPassword
