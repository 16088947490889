import React, { useEffect } from 'react'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete'
import styled from 'styled-components'

const ContentWrrapper = styled('div')`
    ${({ theme }) => `
    width: 100%;
    display: flex;
    align-item: center;
    justify-content: center;
    flex-direction: column;
  `}
`

const SearchInput = styled('input')`
    ${({ theme }) => `
        width: 100%;
        height:2.5rem;
        background:${theme?.colors?.grey};
        border:none;
        border-radius:${theme?.spacing?.spc10};
        margin-bottom: ${theme?.spacing?.spc20};
  `}
`

const ListWrapper = styled('div')`
    ${({ theme }) => `
    width: 100%;
    position: relative;
    list-style: none;
  `}
`

const PositionList = styled('ul')`
    ${({ theme }) => `
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
    position: absolute;
    top: -15px;
    right: 0;
    z-index: 2;
    background: ${theme?.colors?.white};
    border: 1px solid ${theme?.colors?.secondBase};
    border-radius: ${theme?.spacing?.spc4};
  `}
`

type Props = {
    stripped: number
}

const ListItem = styled('li')<Pick<Props, 'stripped'>>`
    ${({ theme, stripped }) => `
    padding: ${theme?.spacing?.spc6} ${theme?.spacing?.spc10};
    cursor: pointer;
    border-radius: ${theme?.spacing?.spc4};
    background: ${stripped % 2 ? '#f5f5f5' : '#fff'};

    &:hover{
      background: ${theme?.colors?.greyLight};
    }
  `}
`

const searchOptions = {
    componentRestrictions: { country: 'cl' },
}

function MapsAutoComplete({
    setPosition,
    handleChange,
    state,
    setState,
    fieldName,
    setFieldValue,
    initalPosition,
}: any) {
    useEffect(() => {
        if (initalPosition?.lat && initalPosition?.lng) {
            setPosition(initalPosition)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initalPosition])

    const handleSelect = (newState: any) => {
        setState(newState)

        geocodeByAddress(newState)
            .then((results: any) => getLatLng(results[0]))
            .then((latLng: any) => {
                setPosition(latLng)
                setFieldValue(
                    fieldName,
                    JSON.stringify({ name: newState, position: latLng })
                )
            })
            .catch((error: any) => console.error('Error', error))
    }

    const getName = (value: any) => {
        try {
            return JSON.parse(value)?.name
        } catch (error) {
            return value
        }
    }

    return (
        <PlacesAutocomplete
            value={getName(state)}
            onChange={handleChange}
            onSelect={handleSelect}
            searchOptions={searchOptions}
        >
            {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
            }: any) => (
                <ContentWrrapper>
                    <SearchInput
                        {...getInputProps({
                            placeholder: 'Busca tu Box...',
                            className: 'location-search-input',
                        })}
                    />
                    {loading && <div>Cargando...</div>}
                    <ListWrapper>
                        {suggestions.length > 0 && (
                            <PositionList>
                                {suggestions.map(
                                    (suggestion: any, index: number) => {
                                        return (
                                            <ListItem
                                                {...getSuggestionItemProps(
                                                    suggestion
                                                )}
                                                key={index}
                                                stripped={index}
                                            >
                                                <span>
                                                    {suggestion.description}
                                                </span>
                                            </ListItem>
                                        )
                                    }
                                )}
                            </PositionList>
                        )}
                    </ListWrapper>
                </ContentWrrapper>
            )}
        </PlacesAutocomplete>
    )
}

export default MapsAutoComplete
