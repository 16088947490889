// Modules
import React, { useState } from 'react'
import styled from 'styled-components'
import { FormikHelpers } from 'formik'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Alert from '@mui/material/Alert'

// Interfaces
import { FormValues } from './interface'

// Forms
import RecoveryPasswordForm from '../../Forms/RecoveryPasswordForm'

// Assets
import FlipitLogo from '../../assets/logo.svg'

// Redux - Actions
import { toastErro } from '../../redux/toast/actions'

// Services
import { forgetPassword } from '../../services/auth'

const MainContainer = styled('div')`
    ${({ theme }) => `
      width:100%;
      height:100vh;
      display:flex;
      align-items:center;
      justify-content:center;
      background:#efefef;
    `}
`

const BoxLogin = styled('div')`
    ${({ theme }) => `
      width:100%;
      max-width: 26rem;
      height:30rem;
      display:flex;
      align-items:center;
      justify-content:center;
      flex-direction:column;
      background:${theme?.colors?.white};
      border-radius:${theme?.spacing?.spc10};
      box-shadow: -1px 2px 15px 8px rgba(0,0,0,0.2);
      @media screen and (max-width:768px){
        height:30rem;
      }
    `}
`

const TopBox = styled('div')`
    ${({ theme }) => `
        display:flex;
        align-items:center;
        justify-content:center;
        @media screen and (max-width:768px){
            width:100%;
        }
  `}
`
const LogoLogin = styled('img')`
    ${({ theme }) => `
        width: 14rem;
        cursor: pointer;
    `}
`

const Title = styled('span')`
    ${({ theme }) => `
        font-size:${theme?.fontSizes?.font26};
        color:${theme?.colors?.middleGreen};
        margin-top:${theme?.spacing?.spc35};
        font-weight: bold;
        font-family:Arial, Helvetica, sans-serif;
  `}
`

const Subtitle = styled('span')`
    ${({ theme }) => `
        font-size:${theme?.fontSizes?.font16};
        color:${theme?.colors?.greyBase};
        text-align:center;
        font-weight: bold;
        font-family:Arial, Helvetica, sans-serif;
        margin-top:${theme?.spacing?.spc20};
        margin-bottom:${theme?.spacing?.spc20};
    `}
`

const Text = styled('span')`
    ${({ theme }) => `
        font-size:${theme?.fontSizes?.font16};
        color:${theme?.colors?.darkBlue};
        margin-top:${theme?.spacing?.spc35};
        margin-bottom:${theme?.spacing?.spc10};
        font-family:Arial, Helvetica, sans-serif;
  `}
`

const AcessBox = styled('div')`
    ${({ theme }) => `
        display: flex;
        text-align: center;
        margin-top:${theme?.spacing?.spc45};
    `}
`

const AcessText = styled('a')`
    ${({ theme }) => `
        font-size:${theme?.fontSizes?.font18};
        color:${theme?.colors?.darkBlue};
        font-weight: bold;
        font-family:Arial, Helvetica, sans-serif;
        cursor:pointer;
        text-decoration:underline;
    `}
`

const WrapperSubmited = styled('div')`
    ${({ theme }) => `
        padding: ${theme?.spacing?.spc20} ${theme?.spacing?.spc20};
        display:flex;
        align-items:center;
        justify-content:center;
        flex-direction: column;
  `}
`

function RecoveryPassword() {
    // States
    const [isSubmited, setIsSubmited] = useState(false)

    // Hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // Methods
    const handleSubmit = (
        values: FormValues,
        actions: FormikHelpers<FormValues>
    ) => {
        forgetPassword(values.email)
            .then((result: any) => {
                setIsSubmited(true)
            })
            .catch((err: any) => {
                dispatch(toastErro('Error al enviar correo!'))
            })
        actions.setSubmitting(false)
    }

    const initialValues: FormValues = { email: '' }

    return (
        <MainContainer>
            <BoxLogin>
                <TopBox>
                    <LogoLogin
                        onClick={() => navigate('/')}
                        src={FlipitLogo}
                        alt="ID Flipit"
                    />
                </TopBox>
                {isSubmited ? (
                    <WrapperSubmited>
                        <Alert severity="success" style={{ width: '100%' }}>
                            Te enviamos un link a tu correo electrónico para que
                            puedas restablecer tu contraseña
                        </Alert>
                        <Title>Revisa tu correo</Title>
                        <Text>
                            Le enviamos un enlace a su correo electrónico. Haga
                            clic para recuperar su contraseña.
                        </Text>
                        <AcessBox>
                            <AcessText onClick={() => navigate('/')}>
                                Regresar al inicio de sesión
                            </AcessText>
                        </AcessBox>
                    </WrapperSubmited>
                ) : (
                    <>
                        <Title>¿Olvidaste tú contraseña?</Title>

                        <Subtitle>
                            Ingresa tu correo electrónico y te enviaremos un
                            correo para que se restablecer tu contraseña
                        </Subtitle>

                        <RecoveryPasswordForm
                            handleSubmit={handleSubmit}
                            initialValues={initialValues}
                            isLoading={false}
                        />
                    </>
                )}
            </BoxLogin>
        </MainContainer>
    )
}
export default RecoveryPassword
