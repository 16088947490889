import React, { useEffect } from 'react'
import styled from 'styled-components'
import { styled as styledMU, Theme, CSSObject } from '@mui/material/styles'
import {
    CssBaseline,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    List,
    Toolbar,
    AppBarProps as MuiAppBarProps,
    Box,
    AppBar as MuiAppBar,
} from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'
import {
    Menu as MenuIcon,
    Group as GroupIcon,
    Business as BusinessIcon,
    ChevronLeft as ChevronLeftIcon,
} from '@mui/icons-material'

// Interfaces
import { AuxProps, ListItemProps, MenuItemIT } from './interface'

// Assets
import FlipitLogo from '../../assets/logo.svg'
import { useNavigate } from 'react-router-dom'
import AvatarOptions from './AvatarOptions'
import { useDispatch, useSelector } from 'react-redux'
import { isAuthenticated } from './auth'
import { LogoutAction } from '../../redux/auth/actions'

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
})

const DrawerHeader = styledMU('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

interface AppBarProps extends MuiAppBarProps {
    open?: boolean
}

const AppBar = styledMU(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    background: '#21cea4',
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

const Drawer = styledMU(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}))

// Styled components
const LogoLogin = styled('img')`
    ${({ theme }) => `
        width: 10rem;
    `}
`

const ToolbarWrapper = styled('div')`
    ${({ theme }) => `
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    `}
`

const ListItem = styled('li')<Pick<ListItemProps, 'isActive'>>`
    ${({ theme, isActive }) => `
        width: 100%;
        color: ${theme.colors.black};
        display: flex;
        padding: ${theme?.spacing?.spc10} ${theme?.spacing?.spc20};
        cursor: pointer;
        span{
            font-family: raleway;
            font-size:${theme?.fontSizes?.font16};
        }
        
        svg{
            color: ${theme.colors.black};
        }

        ${
            isActive
                ? `
                background: ${theme.colors.darkBlue} !important;
                color: ${theme.colors.white} !important;
                svg{
                    color: ${theme.colors.white} !important;
                }
            `
                : ``
        }

        &:hover{
            background: ${theme.colors.darkBlue};
            color: ${theme.colors.white};
            svg{
                color: ${theme.colors.white};
            }
        }
    `}
`

const Dashboard = ({ children }: AuxProps) => {
    // States
    const [open, setOpen] = React.useState(true)

    // Redux State
    const state: any = useSelector((state) => state)

    // Hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const pathname = window.location.pathname

    // Methods
    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    const menuList: Array<MenuItemIT> = [
        {
            name: 'Usuarios',
            icon: <GroupIcon />,
            link: '/dashboard/users',
            permissions: ['ADMIN'],
        },
        {
            name: 'Mi cuenta',
            icon: <GroupIcon />,
            link: `/dashboard/users/${state?.auth?._id}`,
            permissions: ['CLIENT', 'DENTIST'],
        },
        {
            name: 'Boxes',
            icon: <BusinessIcon />,
            link: '/dashboard/boxes',
            permissions: ['ADMIN', 'DENTIST'],
        },
    ]

    useEffect(() => {
        if (!state?.auth?._id) {
            const auth: boolean = isAuthenticated()
            if (!auth) {
                dispatch(LogoutAction())
                navigate('/')
            }
        }
    }, [dispatch, navigate, state?.auth])

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    {open ? (
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeftIcon style={{ color: '#fff' }} />
                        </IconButton>
                    ) : null}
                    <ToolbarWrapper>
                        <AvatarOptions />
                    </ToolbarWrapper>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <LogoLogin src={FlipitLogo} alt="Flipit" />
                </DrawerHeader>
                <Divider />
                <List>
                    {menuList.map((menuItem: MenuItemIT, index: number) =>
                        menuItem.permissions.includes(state?.auth?.role) ? (
                            <ListItem
                                key={index}
                                onClick={() => navigate(menuItem.link)}
                                isActive={
                                    menuItem.link.split('/')[2] ===
                                    pathname.split('/')[2]
                                }
                            >
                                <ListItemIcon>{menuItem.icon}</ListItemIcon>
                                <ListItemText primary={menuItem.name} />
                            </ListItem>
                        ) : null
                    )}
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3 }}
                style={{ background: '#f5f5f5', minHeight: '100vh' }}
            >
                <DrawerHeader />
                {children}
            </Box>
        </Box>
    )
}

export default Dashboard
