import jwt_decode from 'jwt-decode'
import { LOGIN, LOGOUT } from './types'

export const LoginAction = (token: string | null) => {
    if (token) {
        const decoded = jwt_decode(token)
        localStorage.setItem('token', token)
        return { type: LOGIN, payload: decoded }
    }
}

export const LogoutAction = () => {
    return { type: LOGOUT }
}
