// Modules
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BreadcrumbItem, Panel, TableComp } from '../../components'
import Dashboard from '../Dashboard'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import DeleteBox from './DeleteBox'
import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import { getAllBoxes } from '../../services/boxes'
import { toastErro } from '../../redux/toast/actions'
import { useDispatch } from 'react-redux'

const ActionItem = styled('span')`
    ${({ theme }) => `
        display: flex;
        align-items:center;
        justify-content: center;
        cursor: pointer;
        margin-right:${theme?.spacing?.spc4};
        svg{
            color: ${theme.colors.darkBlue};
            font-size:${theme?.fontSizes?.font24};
        }
    `}
`

const ImageWrapper = styled('span')`
    ${({ theme }) => `
        width: 100%;
        display: flex;
        align-items:center;
        justify-content: center;
    `}
`

const ImageComp = styled('img')`
    ${({ theme }) => `
       height: 5rem;
       width: 5rem;
       border-radius:${theme?.spacing?.spc4};
    `}
`

const Boxes: React.FC<{}> = () => {
    // States
    const [isOpen, setIsOpen] = useState(false)
    const [deleteId, setDeleteId] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [refreshKey, setRefreshKey] = useState(0)

    // Table states
    const [tableData, setTableData] = useState([])
    const [pageSize, setPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [total, setTotal] = useState(0)

    // Hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // Methods
    const addBox = () => {
        navigate(`/dashboard/boxes/addBox`)
    }

    // Columns
    const columns = React.useMemo(() => {
        const editBox = (boxId: any) => {
            navigate(`/dashboard/boxes/${boxId}`)
        }

        const deleteBox = (boxId: string | undefined) => {
            setDeleteId(boxId || '0')
            setIsOpen(true)
        }

        return [
            {
                Header: 'Image',
                accessor: 'image',
                width: 100,
                Cell: (props: any) => {
                    const filePath = props?.row?.original?.image
                    return (
                        <ImageWrapper>
                            <ImageComp src={filePath} alt="sin imagene" />
                        </ImageWrapper>
                    )
                },
            },
            {
                Header: 'Clínica',
                accessor: 'name',
                width: 200,
            },
            {
                Header: 'M2',
                accessor: 'boxSize',
                width: 200,
            },
            {
                Header: 'Dirección',
                accessor: 'address',
                width: 200,
            },
            {
                Header: 'Activo',
                accessor: 'isActive',
                width: 50,
            },
            {
                Header: 'Editar',
                accessor: 'actions',
                width: 50,
                noSort: true,
                Cell: (props: any) => {
                    const boxId = props?.row?.original?._id
                    return (
                        <ActionItem>
                            <ModeEditIcon onClick={() => editBox(boxId)} />
                            <DeleteForeverIcon
                                onClick={() => deleteBox(boxId)}
                            />
                        </ActionItem>
                    )
                },
            },
        ]
    }, [navigate])

    // Use Effects
    useEffect(() => {
        getAllBoxes()
            .then((resp: any) => {
                const arrBoxes: any = []
                resp?.data?.data?.data?.forEach((box: any) => {
                    arrBoxes.push({
                        _id: box?._id,
                        image: box?.images[0]?.url,
                        name: box?.name,
                        boxSize: box?.boxSize,
                        address: JSON.parse(box?.address)?.name,
                        isActive: box?.isActive ? 'Sí' : 'No',
                    })
                })
                setTableData(arrBoxes)
                setTotal(resp?.data?.data?.total)
                setIsLoading(false)
            })
            .catch((err: any) => {
                dispatch(toastErro('Erro!'))
                setIsLoading(false)
            })
    }, [dispatch, refreshKey])

    const breadcrumbs = [<BreadcrumbItem text={'Boxes'} />]

    return (
        <Dashboard>
            <>
                <Panel
                    title={'Gestión de boxes'}
                    buttons={[
                        <Button
                            onClick={() => addBox()}
                            variant="outlined"
                            style={{
                                background: '#0b1843',
                                color: '#fff',
                            }}
                        >
                            Crear Box
                        </Button>,
                    ]}
                    breadcrumbs={breadcrumbs}
                    isLoading={isLoading}
                >
                    <TableComp
                        columns={columns}
                        data={tableData}
                        canPreviousPage={currentPage !== 1}
                        canNextPage={
                            currentPage !== Math.ceil(total / pageSize)
                        }
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={Math.ceil(total / pageSize)}
                    />
                </Panel>
                <DeleteBox
                    id={deleteId}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    setRefreshKey={setRefreshKey}
                />
            </>
        </Dashboard>
    )
}

export default Boxes
