// Modules
import React, { useEffect, useState } from 'react'
import { FormikHelpers } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Grid } from '@mui/material'
import { LoginAction } from '../../redux/auth/actions'
import { toastErro } from '../../redux/toast/actions'

// Forms
import LoginForm from '../../Forms/LoginForm'

// Assets
import FlipitLogo from '../../assets/logo.svg'

// Services
import { loginUser } from '../../services/auth'

// Interfaces
import { FormValues } from './interface'

const MainContainer = styled('div')`
    ${({ theme }) => `
      width:100%;
      height:100vh;
      display:flex;
      align-items:center;
      justify-content:center;
      background:${theme?.colors?.grey};
    `}
`

const BoxLogin = styled('div')`
    ${({ theme }) => `
        width:100%;
        max-width: 26rem;
        height: 30rem;
        display:flex;
        align-items:center;
        flex-direction:column;
        background:${theme?.colors?.white};
        border-radius:${theme?.spacing?.spc10};
        box-shadow: -1px 2px 15px 8px rgba(0,0,0,0.2);
        @media screen and (max-width:768px){
            height:30rem;
        }
    `}
`

const TopBox = styled('div')`
    ${({ theme }) => `
        display:flex;
        align-items:center;
        justify-content:center;
        @media screen and (max-width:768px){
            width:100%;
        }
  `}
`
const LogoLogin = styled('img')`
    ${({ theme }) => `
        width: 14rem;
        margin-top:${theme?.spacing?.spc20};
        cursor: pointer;
    `}
`

const Title = styled('span')`
    ${({ theme }) => `
        font-size:${theme?.fontSizes?.font16};
        color:${theme?.colors?.darkBlue};
        margin-top:${theme?.spacing?.spc20};
        text-align: center;
        font-family:raleway;
        @media screen and (max-width:768px){
            font-size:${theme?.fontSizes?.font14};
        }
  `}
`

const ForgetPasswordWrapper = styled('div')`
    ${({ theme }) => `
        width: 100%;
        margin-right:${theme?.spacing?.spc40};
        margin-top:${theme?.spacing?.spc25};
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    `}
`

const ForgetPasswordLink = styled('span')`
    ${({ theme }) => `
        font-size:${theme?.fontSizes?.font16};
        color:${theme?.colors?.darkBlue};
        margin-top:${theme?.spacing?.spc35};
        text-align: rigth;
        font-family:raleway;
        text-decoration: underline;
        cursor: pointer;
    `}
`

function Login() {
    // States
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [refreshKey, setRefreshKey] = useState<number>(0)

    // Hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // Reducers
    const state: any = useSelector((state) => state)

    // Methods
    const handleSubmit = (
        values: FormValues,
        actions: FormikHelpers<FormValues>
    ) => {
        setIsLoading(true)
        loginUser(values.email, values.passwd)
            .then((result: any) => {
                dispatch(LoginAction(result?.data?.data?.access_token))
                setRefreshKey((refreshKey) => refreshKey + 1)
            })
            .catch((err: any) => {
                dispatch(
                    toastErro('El correo y/o la contraseña son incorrectos!')
                )
            })
        actions.setSubmitting(false)
    }

    useEffect(() => {
        switch (state?.auth?.role) {
            case 'ADMIN':
                navigate('/dashboard/users')
                break
            case 'CLIENT':
                navigate(`/dashboard/users/${state?.auth?._id}`)
                break
            case 'DENTIST':
                navigate(`/dashboard/users/${state?.auth?._id}`)
                break
            default:
                break
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshKey])

    const initialValues: FormValues = { email: '', passwd: '' }

    return (
        <Grid container>
            <Grid xs={12} sm={12} md={12} lg={12}>
                <MainContainer>
                    <BoxLogin>
                        <TopBox>
                            <LogoLogin
                                onClick={() => navigate('/')}
                                src={FlipitLogo}
                                alt="Flipit"
                            />
                        </TopBox>
                        <Title>Área administrativa</Title>
                        <LoginForm
                            handleSubmit={handleSubmit}
                            initialValues={initialValues}
                            isLoading={isLoading}
                        />
                        <ForgetPasswordWrapper>
                            <ForgetPasswordLink
                                onClick={() =>
                                    navigate('/auth/forgot-password')
                                }
                            >
                                ¿Olvidaste tú contraseña?
                            </ForgetPasswordLink>
                        </ForgetPasswordWrapper>
                    </BoxLogin>
                </MainContainer>
            </Grid>
        </Grid>
    )
}
export default Login
